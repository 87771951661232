<template>
  <v-row align="center" justify="center" v-if="dialogOpenClose">
    <v-col cols="12">
      <v-row align="center" justify="center">
        <v-col cols="12" xs="12" sm="12" md="10" lg="9">
          <v-card
            align="center"
            justify="center"
            class="dialogOpenClose fullscreen-block"
          >
            <v-card-text>
              <v-container
                ><h1>ประกาศ</h1>
                <h2>{{ rcodeDesc }}</h2>
                <hr width="45%" />
                <br />
                <!-- <v-col> ปิดประกาศ </v-col> -->
                <v-col cols="11" v-if="Number(toDay) <= Number(chkEndDate)">
                  <span
                    >ประกาศปิดให้บริการช่วงโควิด 19 </span
                  >
                  <h3>
                    {{ notice }}
                  </h3>
                  <h5>
                    ปิดให้บริการตั้งแต่วันที่ {{ startDate }} ถึง {{ endDate }}
                  </h5>
                </v-col>

                <v-col
                  cols="11"
                  v-if="
                    Number(toDay) <= Number(chkEndDate) &&
                    (message != '' || message != 'null')
                  "
                >
                  <hr width="50%" />
                  <br />
                  <span>ข่าวประชาสัมพันธ์</span>
                  <h3>
                    {{ message }}
                  </h3>
                </v-col>

                <br />
                <v-col cols="11" v-if="Number(toDay) > Number(chkEndDate)">
                  <h4>
                    {{ message }}
                  </h4>
                </v-col>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { Api } from "../core/utils/api";
// import { GET_NOTICE } from "../constant";

const api = new Api();
export default {
  data: () => ({
    v: process.env.VERSION,
    dialogOpenClose: false,
    rcodeDesc: "",
    addStatus: "",
    startDate: "",
    endDate: "",
    chkStartDate: "",
    chkEndDate: "",
    notice: "",
    message: "",
    chkStatusOpenClose: 0,
    toDay: "",
    montTH: [
      "",
      "ม.ค.",
      "ก.พ.",
      "มี.ค.",
      "เม.ย.",
      "พ.ค.",
      "มิ.ย.",
      "ก.ค.",
      "ส.ค.",
      "ก.ย.",
      "ต.ค.",
      "พ.ย.",
      "ธ.ค.",
    ],
  }),
  async created() {
    let rcodeURL = new URLSearchParams(window.location.search);
    console.log(rcodeURL.has("rcodeWorkingday")); // true
    // console.log(rcodeURL.get("rcodeWorkingday")); // "MyParam"
    let paramUrl = rcodeURL.get("rcodeWorkingday");
    this.toDay = this.formatDate(); // เช็ควันที่
    if (
      rcodeURL.has("rcodeWorkingday") === true ||
      rcodeURL.has("rcodeWorkingday") === "true"
    ) {
      this.getURLQOnline(paramUrl);
    }
  },
  methods: {
    // เช็คค่า rcode จาก url
    async getURLQOnline(params) {
      if (params !== "") {
        this.desserts = [];
        try {
          const controlPlaceTH = await api.GetControlPlaceTH(params);
          // const controlPlaceTH = GET_NOTICE;
          const resData = controlPlaceTH.data;
          // console.log("controlPlaceTH::", controlPlaceTH.data);
          if (resData.length > 0) {
            for (let i = 0; i < resData.length; i++) {
              this.desserts.push(resData[i]);
              this.rcodeDesc = resData[i].rcode_desc;
              this.chkStatusOpenClose = this.subString(
                resData[i].add_status,
                1
              );
              this.chkStartDate = resData[i].add_status;
              this.chkEndDate = resData[i].end_date;
              this.addStatus = this.subString(resData[i].add_status, 2);
              this.startDate = this.getFormatDate(resData[i].start_date);
              this.endDate = this.getFormatDate(resData[i].end_date);
              this.notice = resData[i].notice;
              this.message = resData[i].message;
            }
          }
          this.dialogOpenClose = true;
          console.log("พบข้อมูล");
        } catch (error) {
          if (error.response.status === 404) {
            this.message = "";
            this.dialogOpenClose = true;
            console.log("ไม่พบข้อมูล");
          } else if (error.response.status === 401) {
            alert("Unauthorized");
            this.$emit("PAGES_QUEUE", 0);
          } else {
            alert(error.response.data);
          }
        }
      }
    },
    subString(sText, val) {
      let retString = "";
      if (val === 1) {
        retString = sText.substring(0, 1);
      } else if (val === 2) {
        retString = sText.substring(2, 30);
      }
      return retString;
    },
    formatDate() {
      const date = new Date();
      const result =
        "" +
        (date.getFullYear() + 543) +
        (date.getMonth() + 1 > 9 ? "" : "0") +
        (date.getMonth() + 1) +
        (date.getDate() > 9 ? "" : "0") +
        date.getDate();
      return result;
    },
    getFormatDate(date) {
      let rDate;
      if (date !== 0) {
        const d = String(date).substr(6, 2);
        const m = String(date).substr(4, 2);
        const y = String(date).substr(0, 4);
        rDate = `${Number(d)} ${this.montTH[Number(m)]} ${y}`;
      } else {
        rDate = "";
      }
      return rDate;
    },
  },
};
</script>
<style lang="scss" scoped>
.dialogOpenClose {
  margin-top: 3rem;
  h1 {
    margin-top: 1rem;
    font-size: 1.3rem;
    line-height: 2;
    color: black;
  }
  h2 {
    font-size: 0.9rem;
    line-height: 1.7;
    margin-bottom: 0.5em;
    color: black;
  }
  h3 {
    white-space: pre-wrap;
    margin-top: 1rem;
    font-size: 0.9rem;
    line-height: 1.8;
    color: black;
  }
  h4 {
    white-space: pre-wrap;
    margin-top: 1rem;
    font-size: 0.9rem;
    line-height: 1.5;
    margin-top: 0.8rem;
    color: black;
  }
  h5 {
    font-size: 1.1rem;
    margin-top: 1.9rem;
    margin-bottom: 0.5em;
    color: red;
  }
  span {
    white-space: pre-wrap;
    margin-top: 1rem;
    font-size: 1.1rem;
    line-height: 1.8;
    color: #2750b9;

  }
}
.fullscreen-block {
  background-image: url("../assets/virus.png");
  background-repeat: repeat;
  background-position: right top;
  background-attachment: scroll;
}
</style>
