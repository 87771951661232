import {
  ILogin,
  IReturnLogin,
  IGetBranch,
  IReturnBranch,
  IGetBranchDetail,
  IReturnBranchDetail,
  IGetQuota,
  IReturnQuota,
  IGetReserve,
  IReturnInsertReserve,
  IInsertReserve,
  IReturnReserve,
  IGetHoliday,
  IReturnHoliday,
  IGetReserveOwn,
  IReturnGetReserveOwn,
  IPostCancelReserve,
  IReturnPostCancelReserve,
  IGetControlPlaceTH,
  IReturnGetControlPlaceTH
} from "./../apis/api-types";
import axios, { AxiosStatic, AxiosPromise, AxiosRequestConfig } from "axios";
import { IOptionApi } from "@/core/@types/api";
export class Api {
  /**
   * TODO put your apis here
   */

  public Login(body: ILogin) {
    return this.api<ILogin, IReturnLogin>("/api/login", {
      method: "POST",
      body: body
    });
  }

  public Branch(ccaaCode: number) {
    return this.api<IGetBranch, IReturnBranch[]>(`api/branch/${ccaaCode}`, {
      method: "GET"
    });
  }

  public BranchDetail(rcode: number) {
    return this.api<IGetBranchDetail, IReturnBranchDetail>(`api/branch-detail/${rcode}`, {
      method: "GET"
    });
  }

  public Quota(data: IGetQuota) {
    return this.api<IGetQuota, IReturnQuota>(`api/quota/`, {
      method: "GET",
      queryString: data
    });
  }

  public GetReserve(data: IGetReserve) {
    return this.api<IGetReserve, IReturnReserve[]>(`api/reserve/`, {
      method: "GET",
      queryString: data
    });
  }

  public InsertReserve(body: IInsertReserve) {
    return this.api<IInsertReserve, IReturnInsertReserve>(`api/reserve`, {
      method: "POST",
      body: body
    });
  }

  public GetReserveOwn() {
    return this.api<IGetReserveOwn, IReturnGetReserveOwn[]>(`api/reserve/own`, {
      method: "GET"
    });
  }

  public CancelReserve(txId: number) {
    return this.api<IPostCancelReserve, IReturnPostCancelReserve>(`api/cancel/${txId}`, {
      method: "POST"
    });
  }

  public CheckHoliday(param: IGetHoliday) {
    return this.api<IGetHoliday, IReturnHoliday>(`api/holiday`, {
      method: "GET",
      queryString: param
    });
  }

  public PostRating(data: any) {
    return this.api<IGetHoliday, IReturnHoliday>("api/rating", {
      method: "POST",
      body: data
    });
  }

  public GetControlPlaceTH(rcode : number) {
    return this.api<IGetControlPlaceTH, IReturnGetControlPlaceTH>(`api/control-place-th/${rcode}`, {
      method: "GET",
    });
  }

  public getCatm(cc: string, aa: string, rcode: string) {
    console.log();

    if (cc === "" && aa === "" && rcode === "") {
      return this.api<any, any>("api/province/", {
        method: "GET"
      })
    }
    else if (cc !== "" && aa === "" && rcode === "") {
      return this.api<any, any>(`api/province/${cc}/district/`, {
        method: "GET"
      })
    }
    else if (cc !== "" && aa !== "" && rcode === "") {
      return this.api<any, any>(`api/listRcode/${cc}${aa}/`, {
        method: "GET"
      })
    }
  }

  public logOut() {
    return this.api<any, any>(`api/logout/`, {
      method: "GET"
    });
  }

  public Verify() {
    return this.api<any, any>(`api/verifytoken/`, {
      method: "GET"
    });
  }

  /**
   *
   * @param url endpoint
   * @param option option of this endpoint
   * @param request axios
   * * T = Body type  if method = get you can ignore this by using any type
   * * U = Return Type required
   */
  private api<T, U>(
    url: string,
    option: IOptionApi<T>,
    request: AxiosStatic = axios
  ): AxiosPromise<U> {
    const { method, body, queryString } = option;

    const config: AxiosRequestConfig = {
      method,
      url,
      data: body,
      headers: {
        "Content-Type": "application/json"
      }
    };

    if (method === "GET" || method === "DELETE") {
      if (method === "DELETE") {
        return request(config);
      }
      /**
       * * { "user": 1,"car":2} => /path/?user=1&car=2
       */
      if (queryString) {
        if (typeof queryString !== "object") {
          throw new Error("queryString must be an object");
        }
        config.url += this.makeQueryString(queryString);
      }

      return request(config);
    }
    return request(config);
  }

  private makeQueryString = (queryString: any): string => {
    if (typeof queryString !== "object") {
      throw new Error("queryString must be object");
    }
    let query = "?";
    Object.keys(queryString).forEach((key: string, index: number) => {
      query += `${key}=${queryString[key]}`;
      // last one
      if (index === Object.keys(queryString).length - 1) {
        return;
      }
      query += "&";
    });
    return query;
  };
}
