import Vue from "vue";
import Vuex from "vuex";
import axios from 'axios';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        visit: 0
    },
    getters: {
        getVisit(state) {
            return state.visit
        }
    },
    mutations: {
        setVisit(state, visit) {
            state.visit = visit;
        },
    },
    actions: {
        async hitCountVisit({ commit }) {
            const API_COUNT = 'https://api.countapi.xyz/hit/q-online.bora.dopa.go.th/?amount=1'
            const resCount = await axios.get(API_COUNT)
            commit("setVisit", resCount.data.value);
        },
        async getCountVisit({ commit }) {
            const API_COUNT = 'https://api.countapi.xyz/get/q-online.bora.dopa.go.th/?amount=1'
            const resCount = await axios.get(API_COUNT)
            commit("setVisit", resCount.data.value);
        }
    }
});
