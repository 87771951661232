<template>
  <v-container class="fill-height header" fluid>
    <v-row align="start" justify="center">
      <v-col cols="12" sm="12" md="12" lg="12">
        <h3>ระบบลงทะเบียนขอรับบริการล่วงหน้า ณ สำนักทะเบียนอำเภอ</h3></v-col
      >
      <v-col cols="12" sm="12" md="5" lg="5">
        <v-row align="center" justify="center"
          ><v-col cols="12">
            <h3>หมายเลขนัดหมายล่วงหน้า ของคุณ คือ :</h3>
          </v-col>
          <v-col cols="12">
            <h1 style="color: blue">{{ dataQ.txId }}</h1>
          </v-col>
          <v-col cols="12">
            <h3>วันที่ {{ dataQ.date }}</h3>
          </v-col>
          <v-col cols="12">
            <h3>{{ dataQ.jobService }}</h3>
          </v-col>
          <v-col cols="12">
            <h3>{{ dataQ.rcode }}</h3>
          </v-col>
          <v-col cols="12">
            <h3>{{ dataQ.timeQ }}</h3>
          </v-col></v-row
        >
      </v-col>
      <v-col cols="11" sm="11" md="6" lg="6" v-if="dataQ.typePage === 1"
        ><doc-list-component :serviceId="dataQ.service"
      /></v-col>
      <v-col cols="12">
        <h3 style="color: red" class="mt-4">
          กรุณาแจ้ง หมายเลขนัดหมายล่วงหน้า หรือ เลขประจำตัวประชาชน <br />
          ต่อเจ้าหน้าที่เมื่อเข้ารับบริการตามวันเวลาที่จอง
        </h3>
      </v-col>
      <v-col cols="10" sm="10" md="2" lg="2" class="header__btn">
        <v-btn block color="#363576" @click="print" outlined dark>พิมพ์</v-btn>
      </v-col>
    </v-row>
    <dialog-rating :dataQ="dataQ" />
  </v-container>
</template>

<script>
import docListComponent from "../components/queue-detail.components/detail-doc.vue";
import dialogRating from "./dialog-rating.vue";

export default {
  props: {
    dataQ: Object,
  },
  components: {
    docListComponent,
    dialogRating,
  },
  data: () => ({ test: "MENU" }),
  methods: {
    back() {
      this.$emit("PAGES_QUEUE", 1);
    },
    print: async function () {
      await this.$emit("OPEN_NEV", false);
      window.print();
    },
  },
};
</script>
