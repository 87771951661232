
import Vue from "vue";
import { Api } from "../core/utils/api";
import BaseDialog from "../core/components/base-dialog.vue";
import { GET_RCODE_SERVICE, GET_QUOTA } from "../constant";
import { mask } from "vue-the-mask";

const api = new Api();

export default Vue.extend({
  directives: { mask },
  props: {
    typePage: Number,
    dataLogin: Object,
  },
  components: {
    BaseDialog,
  },
  data: () => ({
    namePage: "",
    dialogWarning: false,
    dialogAlert: false,
    preDesserts: [] as any,
    desserts: [] as any,
    selected: [],
    headers: [
      {
        text: "รหัสการจอง",
        align: "start",
        sortable: false,
        value: "txId",
      },
      { text: "บริการ", value: "serviceDesc" },
      { text: "ช่วงเวลา", align: "center", value: "period" },
      { text: "วันที่จอง", value: "shwDate" },
      { text: "สถานะการจอง", value: "statusDesc" },
    ],
  }),

  created() {
    this.getApiReserveOwn();
    if (this.typePage === 6) {
      this.namePage = "ยกเลิกนัดหมายรับบริการ";
    } else {
      this.namePage = "ยกเลิกขอคัดและรับรองเอกสารล่วงหน้า";
    }
  },
  watch: {
    typePage(value) {
      if (value === 6) {
        this.namePage = "ยกเลิกนัดหมายรับบริการ";
      } else {
        this.namePage = "ยกเลิกขอคัดและรับรองเอกสารล่วงหน้า";
      }
    },
  },
  methods: {
    async getApiReserveOwn() {
      this.desserts = [];
      this.preDesserts = [];
      try {
        const resListReserveOwn = await api.GetReserveOwn();

        for (let i = 0; i < resListReserveOwn.data.length; i += 1) {
          this.preDesserts.push(resListReserveOwn.data[i]);
          this.preDesserts[i].period =
            this.preDesserts[i].period === 1 ? "เช้า" : "บ่าย";
          this.preDesserts[i].shwDate = this.dateFormat(
            this.preDesserts[i].ticketDate
          );
        }
        // push สำหรับแสดงผล
        for (let j = 0; j < this.preDesserts.length; j += 1) {
          if ((this.preDesserts[j].serviceGroup === 1) || (this.preDesserts[j].serviceGroup === 2)) {
            this.desserts.push(this.preDesserts[j]);
          }
        }
      } catch (error) {
        if (error.response.status === 400) {
          alert("Bad field name in query string");
        } else if (error.response.status === 401) {
          alert("Unauthorized");
        } else if (error.response.status === 403) {
          alert("Access Denied");
        } else if (error.response.status === 404) {
          // alert("ไม่พบข้อมูลการจอง");
        } else if (error.response.status === 405) {
          alert("Method Not Allowed");
        } else if (error.response.status === 408) {
          alert("Request Timeout");
        } else if (error.response.status === 500) {
          alert("Internal Server Error");
        } else {
          alert("ปรับปรุงข้อมูลไม่สำเร็จ");
        }
      }
    },
    dateFormat(date: any) {
      const dateNewTH = (this as any).$moment(date).format("Do MMMM YYYY dd");
      const dateSplitTH = dateNewTH.split(" ");
      const dateTH = `${dateSplitTH[0]} ${dateSplitTH[1]} ${
        Number(dateSplitTH[2]) + 543
      }`;
      return dateTH;
    },
    async toCancelReserve(valCancel: any) {
      if (valCancel.length !== 0) {
        try {
          for (let i = 0; i < valCancel.length; i += 1) {
            await api.CancelReserve(valCancel[i].txId);
          }
          this.dialogAlert = true;
          await this.getApiReserveOwn();
          // this.desserts = { ...this.desserts };
        } catch (error) {
          if (error.response.status === 400) {
            alert("Bad field name in query string");
          } else if (error.response.status === 401) {
            alert("Unauthorized");
          } else if (error.response.status === 403) {
            alert("Access Denied");
          } else if (error.response.status === 404) {
            alert("ไม่พบข้อมูล");
          } else if (error.response.status === 405) {
            alert("Method Not Allowed");
          } else if (error.response.status === 408) {
            alert("Request Timeout");
          } else if (error.response.status === 409) {
            alert("ไม่สามารถเพิ่มข้อมูลได้ เนื่องจากมีข้อมูลอยู่แล้ว");
          } else if (error.response.status === 500) {
            alert("Internal Server Error");
          } else {
            alert("ปรับปรุงข้อมูลไม่สำเร็จ");
          }
        }
      } else {
        // alert("กรุณาเลือกรายการที่ต้องการยกเลิก s");
        this.dialogWarning = true;
      }
      console.log("slt", valCancel);
    },
  },
});
