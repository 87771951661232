export function rcodeCheck(rcode: any) {
    const rcodeList = ["1251", "1297", "1204", "4101", "4401", "4501", "5201"]
    let status = false
    let tel = ""

    switch (rcode.rcode) {
        case "1299":
            status = true;
            tel = "02-5890500";
            break;
        case "1297":
            status = true;
            tel = "02-9609704-14 ต่อ 110";
            break;
        case "1298":
            status = true;
            tel = "02-5717672-76";
            break;
        case "4199":
            status = true;
            tel = "1132";
            break;
        case "4499":
            status = true;
            tel = "043-725573-6";
            break;
        case "4599":
            status = true;
            tel = "043-511222 ต่อ 209-211";
            break;
        case "5299":
            status = true;
            tel = "054-237237";
            break;
        default:
            break;
    }
    let dataReturn
    if (status)
        dataReturn = `หากต้องการรับบริการ ณ สำนักทะเบียน${rcode.rcodeDesc} กรุณาติดต่อที่หมายเลขโทรศัพท์ ${tel}`
    else dataReturn = status

    return dataReturn
}
