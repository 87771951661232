
import Vue from "vue";
export default Vue.extend({
  props: {
    show: {
      type: Boolean,
      required: true,
      default: () => false
    },
    onClickYes: {
      type: Function,
      required: false,
      default: () => () => {}
    },
    onClickNo: {
      type: Function,
      required: false,
      default: () => () => {}
    },
    nameOnClickYes: {
      type: String,
      required: false
    },
    nameOnClickNo: {
      type: String,
      required: false
    },
    header: {
      type: String,
      required: false
    },
    body: {
      type: String,
      required: false
    }
  },
  data: () => ({})
});
