<template>
  <v-app id="inspire">
    <div v-if="isLogin == false">
      <login
        @auth="getLogin"
        @DATA_LOGIN="getDataLogin"
        @PAGES_QUEUE="getPage"
        @LOGIN_DOPA="getLoginByDopa"
      />
    </div>
    <div v-else>
      <div class="base__navbar">
        <v-navigation-drawer :clipped="$vuetify.breakpoint.lgAndUp" v-model="drawer" app>
          <v-list dense>
            <v-list-item-group v-model="isList" mandatory color="indigo">
              <v-list-item @click="isPage = 1">
                <v-list-item-content>
                  <v-list-item-title>ระบบนัดหมายรับบริการ</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="isPage = 2">
                <v-list-item-content>
                  <v-list-item-title>ระบบขอคัดและรับรองเอกสาร</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="isPage = 6">
                <v-list-item-content>
                  <v-list-item-title>ยกเลิกนัดหมายรับบริการ</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <!-- <v-list-item @click="isPage = 4">
                <v-list-item-content>
                  <v-list-item-title>ยกเลิกนัดหมายรับบริการทั้งหมด</v-list-item-title>
                </v-list-item-content>
              </v-list-item> -->
              <!-- <v-list-item @click="isPage = 7">
                <v-list-item-content>
                  <v-list-item-title>ยกเลิกขอคัดและรับรองเอกสารล่วงหน้า</v-list-item-title>
                </v-list-item-content>
              </v-list-item> -->
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
      </div>
      <div class="base__appbar">
        <v-app-bar clipped-left app color="#35387a" dark>
          <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon dark href="https://thportal.bora.dopa.go.th/#/menu" v-on="on">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </template>
            <span>กลับหน้าเมนูหลัก</span>
          </v-tooltip> -->
          <v-toolbar-title class="ml-0 pl-4">
            <span class="hidden-sm-and-down"
              >ระบบลงทะเบียนขอรับบริการล่วงหน้า ณ สำนักทะเบียนอำเภอ
            </span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <v-toolbar-title class="ml-0 pl-4">
            <span class="hidden-sm-and-down"
              >bora PORTAL
            </span>
          </v-toolbar-title>
          <v-spacer></v-spacer> -->
          <v-btn text>
            {{ name }}
            <v-icon right>mdi-account</v-icon>
          </v-btn>
          <v-btn text @click.stop="drawer = !drawer">
            <v-icon left>mdi-menu-open</v-icon>
            <h4>เมนู</h4>
          </v-btn>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click="confirmLogout = true">
                <v-icon>mdi-logout-variant</v-icon>
              </v-btn>
            </template>
            <span>ออกจากระบบ</span>
          </v-tooltip>
        </v-app-bar>
      </div>
      <v-content>
        <queues
          @PAGES_QUEUE="getPage"
          @detailQ="getDetailQ"
          v-if="isPage == 1 || isPage == 2"
          :typePage="isPage"
          :dataLogin="dataLogin"
          :isLoginByDopa="loginByDopa"
        />
        <queuedetail
          @PAGES_QUEUE="getPage"
          @OPEN_NEV="OpenNev"
          :dataQ="DetailQ"
          v-if="isPage == 3"
        />
        <listreserveown
          @PAGES_QUEUE="getPage"
          @detailQ="getDetailQ"
          v-if="isPage == 6"
          :typePage="isPage"
          :dataLogin="dataLogin"
        />
      </v-content>
      <base-footer />
    </div>
    <!-- ยกเลิกนัดหมายรับบริการทั้งหมด -->
    <v-dialog v-model="dialog" width="500" persistent>
      <v-card>
        <v-card-title class="grey lighten-2" primary-title>
          {{ header }}
        </v-card-title>
        <v-card-text class="mt-4">
          {{ body }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="confirm">
            {{ nameOnClickNo }}
          </v-btn>
          <v-btn color="primary" @click="deleteApiCancelReserve">
            {{ nameOnClickYes }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- confirm logout -->
    <v-dialog v-model="confirmLogout" width="500" persistent>
      <v-card>
        <v-card-title class="grey lighten-2" primary-title>
          ยืนยันการออกจากระบบ
        </v-card-title>
        <v-card-text class="mt-4">
          ต้องการออกจากระบบใช่หรือไม่ ?
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="confirmLogout = false">
            ยกเลิก
          </v-btn>
          <v-btn color="primary" @click="logoutFunc()">
            ตกลง
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { Api } from "./core/utils/api";
import Queues from "./components/queues.vue";
import Login from "./components/login.vue";
import Queuedetail from "./components/queuedetail.vue";
import BaseFooter from "./core/components/base-footer.vue";
import Listreserveown from "./components/listreserveown.vue";

const api = new Api();

export default {
  name: "App",
  components: {
    Queues,
    Login,
    Queuedetail,
    BaseFooter,
    Listreserveown
  },

  data: () => ({
    isList: 0,
    tab: null,
    isLogin: false,
    name: "",
    isPage: 1,
    dialog: false,
    nameOnClickYes: "",
    nameOnClickNo: "",
    header: "",
    body: "",
    DetailQ: {},
    delete: 0,
    dataLogin: {},
    drawer: false,
    OpenNevBar: "",
    loginByDopa: false,
    confirmLogout: false
  }),
  watch: {
    isPage(value) {
      if (value === 4) {
        this.header = "ยืนยันการยกเลิก";
        this.body = "ยืนยันการยกเลิกการจองนัดหมายรับบริการล่วงหน้าทั้งหมด";
        this.nameOnClickYes = "ยืนยัน";
        this.nameOnClickNo = "ยกเลิก";
        this.dialog = true;
        this.delete = 1;
        this.isPage = 1;
        this.getListApiCancel();
      } else {
        if (value === 5) {
          this.header = "ยืนยันการยกเลิก";
          this.body = "ยืนยันการยกเลิกการจองขอคัดและรับรองเอกสารล่วงหน้าทั้งหมด";
          this.nameOnClickYes = "ยืนยัน";
          this.nameOnClickNo = "ยกเลิก";
          this.dialog = true;
          this.delete = 2;
          this.isPage = 1;
        }
      }
    }
  },
  methods: {
    getLogin: async function(value) {
      console.log("getLogin  :", value);
      this.isLogin = value;
      try {
        if (value) this.$store.dispatch("hitCountVisit");
      } catch (error) {}
    },
    logoutFunc: async function() {
      this.confirmLogout = false;
      this.isPage = 0;
      this.isLogin = false;
      try {
        this.$store.dispatch("getCountVisit");
      } catch (error) {}
      document.cookie = "pid=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      api.logOut();
    },
    getDataLogin: function(value) {
      this.name = value.pid.toString();
      this.dataLogin = value;
    },
    getDetailQ(value) {
      this.DetailQ = value;
    },
    getPage(value) {
      this.isPage = value;
      if (this.isPage === 0) this.logoutFunc();
    },
    getLoginByDopa(value) {
      // console.log("getLoginByDopa::", getLoginByDopa);
      this.loginByDopa = value;
    },
    OpenNev(value) {
      this.drawer = value;
    },
    confirm() {
      this.dialog = false;
    },
    deleteApiCancelReserve: async function() {
      let typeCancel = {
        type: this.delete
      };
      try {
        await api.CancelReserve(typeCancel);
        this.dialog = false;
      } catch (error) {
        alert("ไม่พบข้อมูลการยกเลิก");
      }
    },
    async getListApiCancel() {
      // this.desserts = [];
      try {
        const resListReserveOwn = await api.GetReserveOwn();
        alert("มีข้อมูล");
        console.log("sfa", resListReserveOwn);

        return resListReserveOwn;
      } catch (error) {
        alert("ยังไม่มีรายการนัดหมายรับบริการ");
      }
    }
  }
};
</script>
<style lang="scss">
@import "./core/styles/main.scss";
</style>
