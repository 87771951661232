<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="900">
      <v-card>
        <v-card-title class="headline">ประเมินความพึงพอใจ</v-card-title>
        <v-card-text>
          <v-row justify="center">
            <v-col cols="12">
              <v-item-group v-model="rating">
                <v-container>
                  <v-row justify="center">
                    <v-col v-for="(item, index) in items" :key="index" cols="12" md="2">
                      <v-item v-slot:default="{ active, toggle }">
                        <v-card
                          :color="active ? item.colorActive : item.color"
                          class="d-flex align-center"
                          height="100"
                          @click="toggle"
                          outlined
                          :dark="active"
                        >
                          <v-card-text class="text-center">
                            <v-icon x-large>{{ item.icon }}</v-icon
                            ><br />
                            {{ item.desc }}</v-card-text
                          >
                        </v-card>
                      </v-item>
                    </v-col>
                  </v-row>
                </v-container>
              </v-item-group>
            </v-col>

            <v-col cols="10" md="10">
              <v-textarea
                filled
                name="input-7-4"
                label="ความคิดเห็นเพิ่มเติม"
                v-model="comment"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="blue darken-1 white--text"
            large
            @click="onClick"
            :disabled="rating === null || rating === undefined"
            >ตกลง</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { Api } from "../core/utils/api";

const api = new Api();
export default {
  props: {
    dataQ: Object
  },
  data: () => ({
    dialog: true,
    rating: null,
    comment: "",
    items: [
      {
        id: 1,
        desc: "ปรับปรุง",
        icon: "mdi-emoticon-sad",
        color: "red lighten-3",
        colorActive: "red darken-4"
      },
      {
        id: 2,
        desc: "แย่",
        icon: "mdi-emoticon-neutral",
        color: "orange lighten-4",
        colorActive: "orange darken-4"
      },
      {
        id: 3,
        desc: "ปานกลาง",
        icon: "mdi-emoticon-happy",
        color: "amber lighten-4",
        colorActive: "yellow darken-3"
      },
      {
        id: 4,
        desc: "ดี",
        icon: "mdi-emoticon",
        color: "light-green lighten-4",
        colorActive: "light-green darken-1"
      },
      {
        id: 5,
        desc: "ดีมาก",
        icon: "mdi-emoticon-excited",
        color: "green lighten-4",
        colorActive: "green darken-4"
      }
    ]
  }),
  methods: {
    async onClick() {
      const dataRating = {
        txId: this.dataQ.txId,
        pId: this.dataQ.pid,
        vote: this.items[this.rating].id,
        comments: this.comment
      };
      try {
        const reqRating = await api.PostRating(dataRating);
        alert(reqRating.data);
        this.dialog = false;
      } catch (error) {
        alert("เกิดข้อผิดพลาด ไม่สามารถประเมินความพึ่งพอใจได้");
      }
    }
  }
};
</script>
