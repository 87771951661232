
import Vue from "vue";
import { Api } from "../core/utils/api";
import BaseDialog from "../core/components/base-dialog.vue";
import { GET_RCODE_SERVICE, GET_QUOTA } from "../constant";
import { rcodeCheck } from "../core/utils/rcode-gitignore";
import { mask } from "vue-the-mask";

const api = new Api();
export default Vue.extend({
  directives: { mask },
  props: {
    typePage: Number,
    dataLogin: Object,
    isLoginByDopa: Boolean,
  },
  components: {
    BaseDialog,
  },
  data: () =>
    ({
      date: new Date().toISOString().substr(0, 10),
      dateNow: new Date().toISOString().substr(0, 10),
      dateMin: "",
      dateMax: "",
      dateTH: "",
      dateSendApi: "",
      overlay: false,
      isWorkday: 0,
      maskLaser: "AA#-#######-##",
      Morning: {},
      Atrernoon: {},
      dialogAlert: {
        show: false,
      },
      dialogAlertRcode: {
        show: false,
        body: "",
      },
      dialogMorning: {
        body: "",
        show: false,
      },
      dialogAfter: {
        body: "",
        show: false,
      },
      period: "",
      showLaser: false,
      laser: "",
      fullyM: false,
      fullyA: false,
      namePage: "",
      catm: { RECORDS: [] },
      cc: [],
      aa: [],
      ccSelected: { code: "", name: "" },
      aaSelected: { code: "", name: "" },
      rcodeList: [],
      itemService: [],
      services: null,
      showQComponent: false,
      strNotice: "",
      startDate: "",
      endDate: "",
      montTH: [
        "",
        "ม.ค.",
        "ก.พ.",
        "มี.ค.",
        "เม.ย.",
        "พ.ค.",
        "มิ.ย.",
        "ก.ค.",
        "ส.ค.",
        "ก.ย.",
        "ต.ค.",
        "พ.ย.",
        "ธ.ค.",
      ],
      showControlPlaceTh: false,
      rcode: { rcode: "", rcodeDesc: "" },
      DetailQ: {},
      htmlContentMorning: "",
      htmlContentAfter: "",
      availableDates: [],
      isHoliday: {},
    } as any),
  created() {
    this.getRecordCC();
    this.getDateMin();
    // this.getHoliday();
    if (this.typePage === 1) {
      this.showLaser = false;
      this.namePage = "ระบบนัดหมายรับบริการล่วงหน้า";
      this.services = null;
    } else {
      this.showLaser = true;
      this.namePage = "ระบบขอคัดและรับรองเอกสารล่วงหน้า";
      this.services = null;
    }
  },
  watch: {
    typePage(value) {
      if (value === 1) {
        this.showLaser = false;
        this.namePage = "ระบบนัดหมายรับบริการล่วงหน้า";
        this.services = null;
        this.ClearDate();
      } else {
        this.showLaser = true;
        this.namePage = "ระบบขอคัดและรับรองเอกสารล่วงหน้า";
        this.services = null;
        this.ClearDate();
      }
    },
  },
  methods: {
    getList(item: any, queryText: any, itemText: any) {
      return itemText
        .toLocaleLowerCase()
        .startsWith(queryText.toLocaleLowerCase());
    },
    ClickCancel() {
      this.dialogMorning.show = false;
      this.dialogAfter.show = false;
      this.dialogAlert.show = false;
      this.dialogAlertRcode.show = false;
    },
    async getHoliday(dateVal: any) {
      let dateSplit = dateVal.split("-");
      let dateSltNew = Number(dateSplit[0]) + 543 + dateSplit[1] + dateSplit[2];
      let data = {
        date: dateSltNew,
      };
      try {
        const dateHoliday = await api.CheckHoliday(data);
        // const dateHoliday = await api.CheckHoliday();
        //const dateHoliday = GET_HOLIDAY;
        let elementHoliday = [] as any;
        for (let i = 0; i < dateHoliday.data.holiday.length; i++) {
          elementHoliday.push(dateHoliday.data.holiday[i].date);
        }
        this.isHoliday = await elementHoliday;
        if (dateHoliday.data.holiday.length > 0) {
          return true;
        }
      } catch (error) {
        if (error.response.status >= 400) {
          this.isHoliday = {};
          return false;
        }
      }
    },
    async getRecordCC() {
      const province = await this.connectCatm("", "", "");
      this.cc = [] as any;
      this.ccSelected = { code: "", name: "" };
      province.forEach((element: any) => {
        if ((element["ccCode"] as any) !== "10") {
          this.cc.push({
            CC: {
              code: element["ccCode"] as any,
              name: element["ccDesc"] as any,
            },
            CATM_DESC: element["ccDesc"],
          });
        }
      });
    },
    async getRecordAA() {
      if (this.date !== this.dateNow || this.showQComponent === true) {
        this.ClearDate();
      } else {
        this.ccSelected = { ...this.ccSelected };
        this.aaSelected = { ...this.aaSelected };
        this.rcode = { rcode: "", rcodeDesc: "" };
        this.aa = [];
        this.tt = [];
        this.itemService = [];
        this.services = null;
        this.aaSelected = { code: "", name: "" };

        const district = await this.connectCatm(this.ccSelected.code, "", "");
        district.forEach((element: any) => {
          this.aa.push({
            AA: { code: element["aaCode"], name: element["aaDesc"] },
            CATM_DESC: element["aaDesc"],
          });
        });
      }
    },
    getRecordsCatm() {
      return this.catm;
    },
    async getApiRcode() {
      this.rcodeList = [];
      this.rcode = { rcode: "", rcodeDesc: "" };
      const rcodes = await this.connectCatm(
        this.ccSelected.code,
        this.aaSelected.code,
        ""
      );
      rcodes.forEach((element: any) => {
        this.rcodeList.push({
          RCODE: { rcode: element["rcode"], rcodeDesc: element["rcodeDesc"] },
          RCODE_DESC: element["rcodeDesc"],
        });
      });
      if (rcodes.length > 0) {
        this.rcode = this.rcodeList[0].RCODE;
        this.getItemService(this.rcode.rcode, this.typePage);
      }
    },
    async checkListRcode() {
      if (this.date !== this.dateNow || this.showQComponent === true) {
        this.ClearDate();
      } else {
        const checkRcode = await rcodeCheck(this.rcode);
        if (checkRcode) {
          this.dialogAlertRcode.show = true;
          this.dialogAlertRcode.body = checkRcode;

          this.rcode = { rcode: "", rcodeDesc: "" };
          this.itemService = [];
          this.services = null;
          // this.ClearDate();
        } else {
          this.getItemService(this.rcode.rcode, this.typePage);
        }
      }
    },
    async getItemService(rcode: number, pages: number) {
      this.itemService = [];
      this.services = null;
      try {
        const resBranchDetail = await api.BranchDetail(rcode);
        //const resBranchDetail = GET_RCODE_SERVICE;
        const service = resBranchDetail.data.services;
        for (let i = 0; i < service.length; i++) {
          if (service[i].serviceGroup === pages) {
            if (this.dataLogin.age >= 15) {
              this.itemService.push(service[i]);
            } else {
              if ((service[i].id !== 14 && service[i].id !== 15)) {
                this.itemService.push(service[i]);
              }
            }
          }
        }
      } catch (error) {
        if (error.response.status === 404) {
          alert("ไม่พบข้อมูลงานบริการ");
          this.rcode = { rcode: "", rcodeDesc: "" };
          this.itemService = [];
          this.services = null;
        }
      }
    },
    async checkReqLaser() {
      if (this.date !== this.dateNow || this.showQComponent === true) {
        this.ClearDateChangService();
      } else {
        if (this.services.checkLaser === true) {
          this.showLaser = true;
        }
      }
    },
    dataFormat(date: any) {
      var dateApi = date.split("-");
      this.dateSendApi = `${Number(dateApi[0]) + 543}${dateApi[1]}${
        dateApi[2]
      }`;

      var dateNewTH = this.$moment(date).format("Do MMMM YYYY dd");
      var dateSplitTH = dateNewTH.split(" ");
      this.dateTH = `${dateSplitTH[0]} ${dateSplitTH[1]} พ.ศ. ${
        Number(dateSplitTH[2]) + 543
      }`;

      if (dateSplitTH[3] === "ส." || dateSplitTH[3] === "อา.") {
        this.isWorkday = 0;
      } else {
        this.isWorkday = 1;
      }
    },
    async CheckDataInput() {
      this.showControlPlaceTh = false;
      if (
        this.ccSelected.code !== "" &&
        this.aaSelected.code !== "" &&
        this.rcode.rcode !== "" &&
        Boolean(this.services) !== false
      ) {
        const getHoliday = await this.getHoliday(this.date); // เป็นวันหยุดหรือไม่
        if (getHoliday) {
          // เป็นวันหยุด
          alert("เป็นวันหยุด ไม่เปิดให้บริการ");
          this.ClearDateChangService();
        } else {
          try {
            const controlPlaceTH = await api.GetControlPlaceTH(
              this.rcode.rcode
            );
            const resData: any = controlPlaceTH.data;
            // console.log("controlPlaceTH::", controlPlaceTH.data);
            if (resData.length > 0) {
              if (
                this.chkPeriodWorkDay(
                  resData[0].start_date,
                  resData[0].end_date
                ) === true
              ) {
                // console.log("มีค่าจ้าาา วันที่อยู่ในช่วงปิด", this.date);
                this.dataFormat(this.date);
                this.showControlPlaceTh = true;
                this.showQComponent = false;
                this.strNotice = resData[0].notice;
                this.startDate = this.getFormatDate(resData[0].start_date);
                this.endDate = this.getFormatDate(resData[0].end_date);
                this.ClearDateChangService();
              } else {
                // process เดิม
                // console.log("มีค่า แต่วันที่ไม่ตรง", controlPlaceTH);
                if (this.typePage === 2) {
                  if (this.isLoginByDopa === true) {
                    // Login By D.Dopa
                    await this.getApiQuoat();
                  } else {
                    if (this.laser === "") {
                      alert("กรุณากรอกเลข Laser หลังบัตร");
                    } else {
                      await this.getApiQuoat();
                    }
                  }
                } else {
                  await this.getApiQuoat();
                } // end if (this.typePage === 2)
              }
            }
          } catch (error) {
            if (error.response.status === 404) {
              // process เดิม
              if (this.typePage === 2) {
                if (this.isLoginByDopa === true) {
                  // Login By D.Dopa
                  await this.getApiQuoat();
                } else {
                  if (this.laser === "") {
                    alert("กรุณากรอกเลข Laser หลังบัตร");
                  } else {
                    await this.getApiQuoat();
                  }
                }
              } else {
                await this.getApiQuoat();
              } // end if (this.typePage === 2)
            } else {
              alert(error);
            }
          } // end catch (error) {
        } // end if (getHoliday)
      } else {
        alert("กรุณาเลือกจังหวัด อำเภอ และงานบริการที่ต้องการนัดหมายล่วงหน้า");
      }
    },
    getFormatDate(date: any) {
      let rDate;
      if (date !== 0) {
        const d = String(date).substr(6, 2);
        const m = String(date).substr(4, 2);
        const y = String(date).substr(0, 4);
        rDate = `${Number(d)} ${this.montTH[Number(m)]} ${y}`;
      } else {
        rDate = "";
      }
      return rDate;
    },
    chkPeriodWorkDay(valStartDate: any, valEndDate: any) {
      if (
        Number(this.parseDate(this.date)) >= valStartDate &&
        Number(this.parseDate(this.date)) <= valEndDate
      ) {
        return true;
      } else {
        return false;
      }
    },
    parseDate(str: any) {
      var ymd = str.split("-");
      return `${Number(ymd[0]) + 543}${ymd[1]}${ymd[2]}`;
    },
    async getApiQuoat() {
      if ([undefined, null, ""].includes(this.services.id)) {
        alert("กรุณาเลือกงานบริการและวันที่ ที่ต้องการนัดหมายล่วงหน้า");
        return;
      }
      
      this.showControlPlaceTh = false;
      this.dataFormat(this.date);
      let data = {
        rcode: this.rcode.rcode,
        serviceId: this.services.id,
        isWorkday: this.isWorkday,
        date: this.dateSendApi,
      };
      try {
        this.overlay = true;
        const resQuota = await api.Quota(data);
        //const resQuota = GET_QUOTA;
        const period = resQuota.data.period;
        for (let i = 0; i < period.length; i++) {
          if (period[i].id === 1) {
            this.Morning = period[i];
            if (period[i].Quota === 0) {
              this.Morning.desc = undefined;
            } else {
              if (period[i].Quota <= period[i].current) {
                this.htmlContentMorning = `<h1><font color="red">เต็ม</font></h1>`;
                this.fullyM = true;
              } else {
                this.htmlContentMorning = `<h1>ว่าง</h1>`;
                this.fullyM = false;
              }
            }
          }
          if (period[i].id === 2) {
            this.Atrernoon = period[i];
            if (period[i].Quota === 0) {
              this.Atrernoon.desc = undefined;
            } else {
              if (period[i].Quota <= period[i].current) {
                this.htmlContentAfter = `<h1><font color="red">เต็ม</font></h1>`;
                this.fullyA = true;
              } else {
                this.htmlContentAfter = `<h1>ว่าง</h1>`;
                this.fullyA = false;
              }
            }
          }
        }
        if (
          this.Morning.desc === undefined &&
          this.Atrernoon.desc === undefined
        ) {
          this.showQComponent = false;
          alert("ไม่เปิดให้บริการในวันที่ท่านเลือก");
          this.overlay = false;
        } else {
          this.showQComponent = true;
          this.overlay = false;
        }
      } catch (error) {
        this.showQComponent = false;
        if (error.response.status === 404) {
          alert("ไม่เปิดให้บริการในวันที่ท่านเลือก");
        }
        this.overlay = false;
      }
    },
    async ReserveMorning() {
      try {
        if (this.isLoginByDopa) await api.Verify();
        const checkReserves = await this.getApiCheckReserve();
        if (checkReserves === true) {
          this.dialogMorning.show = true;
          this.dialogMorning.body = `ยืนยันการจองนัดหมายล่วงหน้า ${this.dateTH} <br />
          ${this.services.desc} ที่ ${this.rcode.rcodeDesc} <br /> ${this.Morning.desc}`;
        } else {
          this.period = 1;
        }
      } catch (error) {
        alert("หมดอายุการล็อกอิน กรุณาทำรายการใหม่");
        this.$emit("PAGES_QUEUE", 0);
      }
    },
    async confrimMorning() {
      const resInsert = await this.getApiInsertReserve(1);
      if (resInsert.status === true) {
        this.DetailQ = {
          txId: resInsert.txid,
          date: this.dateTH,
          jobService: this.services.desc,
          rcode: this.rcode.rcodeDesc,
          timeQ: this.Morning.desc,
          service: this.services.id,
          typePage: this.typePage,
          pid: this.dataLogin.pid,
        };
        this.$emit("PAGES_QUEUE", 3);
        this.$emit("detailQ", this.DetailQ);
        this.dialogMorning.show = false;
        this.overlay = false;
      } else {
        this.dialogMorning.show = false;
        this.overlay = false;
      }
    },
    async ReserveAfter() {
      try {
        if (this.isLoginByDopa) await api.Verify();
        const checkReserves = await this.getApiCheckReserve();
        if (checkReserves === true) {
          this.dialogAfter.show = true;
          this.dialogAfter.body = `ยืนยันการจองนัดหมายล่วงหน้า ${this.dateTH} <br />
          ${this.services.desc} ที่${this.rcode.rcodeDesc} <br /> ${this.Atrernoon.desc}`;
        } else {
          this.period = 2;
        }
      } catch (error) {
        alert("หมดล็อกอิน กรุณาทำรายการใหม่");
        this.$emit("PAGES_QUEUE", 0);
      }
    },
    async confrimAfter() {
      const resInsert = await this.getApiInsertReserve(2);
      this.DetailQ = {
        txId: resInsert.txid,
        date: this.dateTH,
        jobService: this.services.desc,
        rcode: this.rcode.rcodeDesc,
        timeQ: this.Atrernoon.desc,
        service: this.services.id,
        typePage: this.typePage,
        pid: this.dataLogin.pid,
      };

      if (resInsert.status === true) {
        this.$emit("PAGES_QUEUE", 3);
        this.$emit("detailQ", this.DetailQ);
        this.dialogAfter.show = false;
        this.overlay = false;
      } else {
        this.dialogAfter.show = false;
        this.overlay = false;
      }
    },
    async getApiCheckReserve() {
      try {
        let data = {
          serviceId: this.services.id,
        };
        const resCheckReserve = await api.GetReserve(data);
        for (let i = 0; i < resCheckReserve.data.length; i++) {
          if (resCheckReserve.data[i].txId !== "") {
            this.dialogAlert.show = true;
            this.dialogAlert.body = `คุณได้ทำการลงทะเบียน รหัส ${resCheckReserve.data[i].txId} ไว้แล้ว ต้องการยกเลิกบริการเดิมหรือไม่`;
          } else {
            if (resCheckReserve.data[i].printDate !== null) {
              this.dialogAlert.show = true;
              this.dialogAlert.body = `เจ้าหน้าที่ได้พิมพ์เอกสารแล้ว วันที่ ${resCheckReserve.data[i].printDate} ...`;
            } else {
              return true;
            }
          }
        }
      } catch (error) {
        if (error.response.status === 404) {
          return true;
        }
        if (error.response.status === 500) {
          alert("เกิดข้อผิดพลาด : [Internal Server Error]");
          this.ClearDate();
        }
        if (error.response.status === 401) {
          alert("กรุณาเข้าสู่ระบบอีกครั้ง");
          this.ClearDate();
          this.$router.push({ path: `/` });
          this.$router.go(0);
        }
      }
    },

    async deleteApiCancelReserve() {
      try {
        const resCancel = await api.CancelReserve(this.typePage);
        this.dialogAlert = false;
        if (this.period === 1) {
          await this.confrimMorning();
        } else {
          await this.confrimAfter();
        }
      } catch (error) {
        alert("เกิดข้อผิดพลาด");
      }
    },

    async getApiInsertReserve(period: number) {
      if (this.typePage === 1) {
        this.laser =
          this.laser.replace(/-/g, "") === ""
            ? "-"
            : this.laser.replace(/-/g, "");
      } else {
        this.laser = this.laser.replace(/-/g, "");
      }
      let body = {
        rcode: this.rcode.rcode,
        serviceId: this.services.id,
        reserveDate: Number(this.dateSendApi),
        period: period,
        tel: this.dataLogin.tel,
        laser: this.laser,
      };

      try {
        this.overlay = true;
        const res = await api.InsertReserve(body);
        let dataRetrun = {
          status: true,
          txid: res.data.txId,
        };
        this.overlay = false;
        return dataRetrun;
      } catch (error) {
        if (error.response.status === 400) {
          alert("เกิดข้อผิดพลาด");
        } else {
          alert(error.response.data);
        }
        let dataRetrun = {
          status: false,
        };
        return dataRetrun;
      }
    },
    allowedDates(a: any) {
      var value =
        typeof this.isHoliday === "string" && this.isHoliday.indexOf(a) > -1;
      return !value;
    },
    getDateMin() {
      let min;
      const today = new Date();
      const time = today.getHours();
      if (time >= 16) {
        min = this.$moment(this.dateNow).add(2, "days");
      } else {
        min = this.$moment(this.dateNow).add(1, "days");
      }
      this.dateMin = this.$moment(min).format("YYYY-MM-DD");
      this.dateMax = this.$moment(this.dateMin).add(14, "days");
      this.dateMax = this.$moment(this.dateMax).format("YYYY-MM-DD");
    },
    ClearDate() {
      this.date = new Date().toISOString().substr(0, 10);
      this.Morning = {};
      this.Atrernoon = {};
      this.dialogAlert = {
        show: false,
      };
      this.dialogMorning = {
        body: "",
        show: false,
      };
      this.dialogAfter = {
        body: "",
        show: false,
      };
      this.laser = "";
      this.fullyM = false;
      this.fullyA = false;
      this.aa = [];
      this.aaSelected = { code: "", name: "" };
      this.itemService = [];
      this.services = null;
      this.showQComponent = false;
      this.DetailQ = {};
      this.htmlContentMorning = "";
      this.htmlContentAfter = "";
      this.rcode = { rcode: "", rcodeDesc: "" };
      this.getDateMin();
      this.getRecordAA();
    },
    ClearDateChangService() {
      this.Morning = {};
      this.Atrernoon = {};
      this.dialogAlert = {
        show: false,
      };
      this.dialogMorning = {
        body: "",
        show: false,
      };
      this.dialogAfter = {
        body: "",
        show: false,
      };
      this.fullyM = false;
      this.fullyA = false;
      this.showQComponent = false;
      this.DetailQ = {};
      this.htmlContentMorning = "";
      this.htmlContentAfter = "";
      this.getDateMin();
    },
    async connectCatm(cc: string, aa: string, rcode: string) {
      const res = await api.getCatm(cc, aa, rcode);
      const catm = (res as any).data;

      return catm;
    },
  },
});
