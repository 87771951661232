<template>
  <v-row align="center" justify="center" class="login" v-if="chkStatusOpenClose === false">
    <v-col cols="12" sm="12" md="4" lg="3" class="login__left hidden-sm-and-down">
      <v-row>
        <v-col cols="3" class="login__left--img">
          <v-img src="../assets/sing.gif" width="100" />
        </v-col>
        <v-col cols="9">
          <v-row>
            <v-col cols="12"> <h1>กรมการปกครอง กระทรวงมหาดไทย</h1></v-col
            ><v-col cols="12"><h2>Department Of Provincial Administration</h2></v-col></v-row
          >
        </v-col>
        <v-row class="login__left--bottom">
          <v-col cols="12"><h1>ลงทะเบียนขอรับบริการล่วงหน้า</h1></v-col>
          <v-col cols="12"
            ><h2>
              นัดหมายเพื่อขอรับบริการล่วงหน้า ณ ที่ว่าการอำเภอ ในการขอรับบริการด้านงานทะเบียน
              และการขอคัดและรับรองเอกสารทางทะเบียน สามารถลงทะเบียนล่วงหน้าได้ 15 วัน
              และช้าสุดไม่เกิน 16.00 น.ก่อนวันเข้ารับบริการ
            </h2>
          </v-col>
          <v-col cols="12" class="login__left--bottom--icon">
            <v-btn icon dark target="_blank" href="https://www.dopa.go.th/main/web_index"
              ><v-icon>mdi-web</v-icon></v-btn
            >
            <v-btn icon dark target="_blank" href="https://www.facebook.com/dopafanpage/"
              ><v-icon>mdi-facebook</v-icon></v-btn
            >
            <v-btn icon dark target="_blank" href="https://www.youtube.com/user/prdopa/featured"
              ><v-icon>mdi-youtube</v-icon></v-btn
            >
          </v-col>
          <v-col cols="12"
            ><p>สงวนลิขสิทธิ์ &copy; 2566 สำนักบริหารการทะเบียน กรมการปกครอง</p>
            <v-row class="ml-1">
              <span> v {{ v }} </span>
              <p class="ml-auto">
                <v-icon dark class="mr-2">mdi-chart-box-plus-outline</v-icon>
                ผู้เข้าชมเว็บไซต์ {{ this.$store.getters.getVisit }} คน
              </p></v-row
            >
          </v-col>
        </v-row>
      </v-row>
    </v-col>
    <v-col cols="12" sm="12" md="8" lg="9">
      <v-row align="center" justify="center" class="login__right">
        <v-col md="6" lg="6" xs="12">
          <v-row align="center" justify="center">
            <v-col cols="12"><h1>ลงทะเบียนขอรับบริการล่วงหน้า</h1></v-col>
            <v-col cols="5">
              <v-btn
                block
                dark
                large
                color="#4682f7"
                outlined
                class="text-capitalize"
                href="https://q-online.bora.dopa.go.th/api/oauth"
              >
                Login By
                <v-avatar tile size="40" class="login__right--avatar">
                  <img src="../assets/logo-d.dopa.png" alt="John" />
                </v-avatar>
              </v-btn>
            </v-col>
            <v-col cols="12"><h2>หรือ กรอกข้อมูลเพื่อเข้าสู่ระบบ</h2></v-col>
            <v-col cols="12">
              <p>เลขประจำตัวประชาชน</p>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-mask="maskPid"
                label="กรอกเลขประจำตัวประชาชน 13 หลัก"
                v-model="pid"
                outlined
                single-line
              ></v-text-field
            ></v-col>
            <v-col cols="12" class="login__right--topHBD">
              <v-radio-group v-model="birthdayChoice" row>
                <span style="padding-right: 10px"><p>วันเดือนปีเกิด</p></span>
                <v-radio label="ทราบวัน เดือน ปีเกิด" value="1"></v-radio>
                <v-radio label="ไม่ทราบวันหรือเดือนเกิด วันที่/เดือน/ปีพ.ศ." value="0"></v-radio>
              </v-radio-group>
            </v-col>
            <!-- <v-col cols="12" class="login__right--top">
              <p>วันเดือนปีเกิด</p>
            </v-col> -->
            <v-col cols="12" class="login__right--top" v-if="birthdayChoice === '1'">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    label="กรอกวันเดือนปีเกิด"
                    v-model="computedDateFormatted"
                    readonly
                    outlined
                    single-line
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  no-title
                  @input="menu = false"
                  ref="picker"
                  :max="new Date().toISOString().substr(0, 10)"
                  min="1871-01-01"
                  locale="th"
                ></v-date-picker> </v-menu
            ></v-col>
            <v-col cols="12" class="login__right--top" v-if="birthdayChoice === '0'">
              <v-text-field
                label="กรอกวันเดือนปีเกิด"
                v-mask="maskBirthday"
                v-model="birthday"
                outlined
                single-line
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" class="login__right--bottom" v-if="birthdayChoice === '0'">
              ตัวอย่างการกรอก วันที่/เดือน/ปีพ.ศ.เกิด (เช่น 00/00/2500)
            </v-col>
            <v-col cols="12" class="login__right--top">
              <p>เบอร์โทร</p>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="กรอกเบอร์โทร 10 หลัก"
                outlined
                v-mask="maskTel"
                v-model="tel"
                single-line
              ></v-text-field
            ></v-col>
            <v-col cols="5">
              <v-btn block dark large color="#4682f7" @click="loginFunc">ตรวจสอบข้อมูล</v-btn>
            </v-col>
          </v-row></v-col
        >
      </v-row>
    </v-col>
  </v-row>

  <v-row align="center" justify="center" v-else-if="chkStatusOpenClose === true">
    <notice />
  </v-row>
</template>

<script>
import { Api } from "../core/utils/api";
import { mask } from "vue-the-mask";
import notice from "./notice.vue";

const api = new Api();
export default {
  components: { notice },
  directives: { mask },
  component: {
    notice
  },
  data: () => ({
    v: process.env.VERSION,
    name: "",
    pid: "",
    maskPid: "#-####-#####-##-#",
    maskTel: "###-###-####",
    maskBirthday: "##/##/####",
    date: "",
    menu: false,
    tel: "",
    dob: "",
    birthdayChoice: "1",
    birthday: "",
    dialogOpenClose: false,
    chkStatusOpenClose: false
  }),
  async created() {
    let rcodeURL = new URLSearchParams(window.location.search);
    if (rcodeURL.has("rcodeWorkingday") === true || rcodeURL.has("rcodeWorkingday") === "true") {
      this.chkStatusOpenClose = true;
    }
    //process เดิม
    try {
      this.$store.dispatch("getCountVisit");
    } catch (error) {
      console.log(error);
    }
    this.format();
    const urlParams = new URLSearchParams(window.location.search);
    // console.log("urlParams::", urlParams);
    const oauth2Success = urlParams.get("oauth2Success");
    console.log("oauth2Success::", oauth2Success);
    if (oauth2Success === "true") {
      // console.log("Thisway");
      this.$emit("auth", oauth2Success === "true");
      const pid = {
        pid: Number(this.get_cookie_pid()),
        tel: " "
      };

      this.$emit("DATA_LOGIN", pid);
      this.$emit("LOGIN_DOPA", true);
      this.$emit("PAGES_QUEUE", 1);
      this.$router.push("/");
    } else {
      if (
        this.get_cookie_pid() != "" &&
        this.get_cookie_pid() != undefined &&
        this.get_cookie_pid() != 0
      ) {
        this.$emit("auth", true);
        const pid = {
          pid: Number(this.get_cookie_pid()),
          tel: " "
        };

        this.$emit("DATA_LOGIN", pid);
        this.$emit("LOGIN_DOPA", true);
        this.$emit("PAGES_QUEUE", 1);
        this.$router.push("/");
      } else {
        this.$emit("auth", false);
        api.logOut();
      }
    }
  },
  methods: {
    get_cookie_pid() {
      return document.cookie.match("(^|;)\\s*" + "pid=" + "\\s*([^;]+)")?.pop() || "";
    },
    getFormatDate(date) {
      let rDate;
      if (date !== 0) {
        const d = String(date).substr(6, 2);
        const m = String(date).substr(4, 2);
        const y = String(date).substr(0, 4);
        rDate = `${Number(d)} ${this.montTH[Number(m)]} ${y}`;
      } else {
        rDate = "";
      }
      return rDate;
    },
    loginFunc: async function() {
      if (this.pid.replace(/-/g, "") !== "" && this.pid.replace(/-/g, "").length === 13) {
        if (this.dob !== "" && this.dob.length === 8) {
          if (this.tel.replace(/-/g, "").length === 10) {
            this.Login();
          } else {
            alert("กรุณากรอกเบอร์โทร");
          }
        } else {
          alert("กรุณาระบุวัน เดือน ปีเกิด ให้ถูกต้อง");
        }
      } else {
        alert("กรุณากรอกเลขประจำตัวประชาชนให้ถูกต้อง");
      }
    },
    Login: async function() {
      try {
        let bodyLogin = {
          pid: this.pid.replace(/-/g, ""),
          dob: Number(this.dob)
        };
        const resLogin = await api.Login(bodyLogin);
        resLogin.data.citizen.tel = this.tel.replace(/-/g, "");
        this.$emit("DATA_LOGIN", resLogin.data.citizen);
        this.$emit("LOGIN_DOPA", false);
        this.$emit("PAGES_QUEUE", 1);
        this.$emit("auth", true);
      } catch (error) {
        console.log("error Login", error);
        switch (error.response.status) {
          case 404:
            alert("กรุณากรอกข้อมูลให้ถูกต้อง");
            break;
          case 401:
            alert("ยืนยันตัวตนไม่สำเร็จ");
            break;
          case 500:
            alert("internal server error");
            break;
          default:
            alert("เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง");
            break;
        }
        this.$emit("auth", false);
      }
    },
    format() {
      var dateNew = this.$moment(this.date).format("Do MMMM YYYY");
      var date = dateNew.split(" ");
      return `${date[0]} ${date[1]} ${Number(date[2]) + 543}`;
    }
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    birthdayChoice() {
      this.date = "";
      this.birthday = "";
    },
    birthday(val) {
      if (this.birthday !== "") {
        var dateDob = this.birthday.split("/");
        this.dob = `${Number(dateDob[2])}${dateDob[1]}${dateDob[0]}`;
      }
    }
  },
  computed: {
    computedDateFormatted() {
      if (this.date !== "") {
        var dateNew = this.$moment(this.date).format("Do MMMM YYYY");
        var date = dateNew.split(" ");
        var dateDob = this.date.split("-");
        this.dob = `${Number(dateDob[0]) + 543}${dateDob[1]}${dateDob[2]}`;
        return `${date[0]} ${date[1]} ${Number(date[2]) + 543}`;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.login {
  &__left {
    padding: 1.5rem;
    background-color: #35387a;
    height: 100vh;
    h1 {
      font-size: 0.8rem;
      color: white;
    }
    h2 {
      margin-top: -1rem;
      font-size: 0.8rem;
      color: white;
    }
    &--img {
      padding-left: 1rem;
      padding-right: 0rem;
    }
    &--bottom {
      position: absolute;
      bottom: 0;
      width: 24%;
      padding-left: 1.5rem;
      h1 {
        font-size: 1.1rem;
      }
      h2 {
        font-size: 0.8rem;
        color: white;
      }
      p {
        color: white;
        font-size: 0.8rem;
      }
      span {
        color: white;
        font-size: 0.8rem;
        margin-bottom: 20%;
      }
      &--icon {
        margin-top: 10%;
      }
    }
  }
  &__right {
    text-align: center;
    padding: 1.2rem;
    height: 90vh;
    &--avatar {
      margin-left: 1rem;
    }
    h1 {
      text-align: left;
      color: #4682f7;
      font-size: 1.4rem;
    }
    h2 {
      margin-top: -1rem;
      text-align: left;
      color: gray;
      font-size: 0.9rem;
      padding-bottom: 0.3rem;
    }
    h3 {
      color: #ed674a;
      font-size: 1.4rem;
      text-align: left;
    }
    p {
      text-align: left;
      color: gray;
      margin-bottom: -1.4rem;
    }
    &--top {
      margin-top: -1.6rem;
    }
    &--topHBD {
      margin-top: -3rem;
    }
    &--bottom {
      text-align: right;
      color: red;
      margin-top: -3rem;
    }
  }
}
</style>
