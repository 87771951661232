<template>
  <v-footer app color="#35387a" dark class="font-weight-medium base__footer">
    สงวนลิขสิทธิ์ &copy; 2566 สำนักบริหารการทะเบียน กรมการปกครอง
    <v-spacer></v-spacer> v {{ v }}
  </v-footer>
</template>
<script>
export default {
  data: () => ({
    v: process.env.VERSION
  })
};
</script>
