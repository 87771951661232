<template>
  <v-col cols="12">
    <v-card outlined class="detail"
      ><v-card-text
        ><h2 class="mb-4">เอกสารหลักฐาน</h2>
        <div v-if="showErr === ''">
          <v-row
            align="start"
            justify="start"
            v-for="(item, index) in listDoc"
            :key="index"
          >
            <v-col cols="1"
              ><h3>{{ index }}</h3>
            </v-col>
            <v-col cols="11"
              ><h3>{{ item }}</h3>
            </v-col></v-row
          >
        </div>
        <div v-else>
          <h4>
            ข้อมูลเพิ่มเติม
            <a v-bind:href="'https://info.go.th/'" target="_blank"
              >https://info.go.th</a
            >
          </h4>
        </div></v-card-text
      ></v-card
    >
  </v-col>
</template>

<script>
import { getList } from "./listDoc";

export default {
  data: () => ({
    listDoc: [],
    showErr: "",
  }),
  props: {
    serviceId: Number,
  },
  created() {
    this.getDoc();
  },
  methods: {
    getDoc() {
      const list = getList(this.serviceId);
      this.listDoc = list.doc_list[0];
      if (this.listDoc.length === 0) {
        this.showErr = "ไม่มีข้อมูล";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.detail {
  h2 {
    padding-top: 1rem;
  }
  h3 {
    text-align: start;
  }
}
</style>
